//import { todosRef } from "../config/firebase";
import * as firebase from "firebase";
import { FETCH_TODOS } from "./types";

//PALMS
import { scoreRef } from "../config/firebase";
import { PDFListRef } from "../config/firebase";

import { FETCH_SCORE } from "./types";
import { FETCH_PDFLIST } from "./types";

// export const addToDo = newToDo => async dispatch => {
//   todosRef.push().set(newToDo);
// };

// export const completeToDo = completeToDoId => async dispatch => {
//   todosRef.child(completeToDoId).remove();
// };

// export const fetchToDos = () => async dispatch => {
//   todosRef.on("value", snapshot => {
//     dispatch({
//       type: FETCH_TODOS,
//       payload: snapshot.val()
//     });
//   });
// };

//PALMS
//add and update score
export const addScore = (newScore) => async (dispatch) => {
  scoreRef.push().set(newScore);
};

export const deleteScore = (deleteScoreId) => async (dispatch) => {
  scoreRef.child(deleteScoreId).remove();
};

export const fetchScore = () => async (dispatch) => {
  scoreRef.on("value", (snapshot) => {
    dispatch({
      type: FETCH_SCORE,
      payload: snapshot.val(),
    });
  });
};

//add and update pdf files
export const addPDF = (newPDF) => async (dispatch) => {
  // var newItemKey = PDFListRef.push().key();
  // console.log(newItemKey);
  console.log("new pdf", newPDF);
  PDFListRef.push().set(newPDF);
};

export const editPDF = (editPDFId, updates) => async (dispatch) => {
  // var newItemKey = PDFListRef.push().key();
  // console.log(newItemKey);
  console.log("edit pdf", editPDFId);
  firebase
    .database()
    .ref("PDFList" + "/" + editPDFId)
    .update(updates);
};

export const deletePDF = (deletePDFId) => async (dispatch) => {
  PDFListRef.child(deletePDFId).remove();
  console.log("key deleted!!");
};

export const fetchPDFList = () => async (dispatch) => {
  PDFListRef.on("value", (snapshot) => {
    dispatch({
      type: FETCH_PDFLIST,
      payload: snapshot.val(),
    });
  });
};
