export const FirebaseConfig = {
  // apiKey: "AIzaSyAT0cyZbPD0fg6bHzVfHCTu2c4j2wGe7Ys",
  // authDomain: "firedux-todo.firebaseapp.com",
  // databaseURL: "https://firedux-todo.firebaseio.com",
  // projectId: "firedux-todo",
  // storageBucket: "firedux-todo.appspot.com",
  // messagingSenderId: "750399659188"

  // For palms, using kenta@kentaccn.com
  apiKey: "AIzaSyCg9aNzgJqMb2ShYBri_Gf1831ZuTO02H0",
  authDomain: "palms-crowdmark.firebaseapp.com",
  databaseURL: "https://palms-crowdmark.firebaseio.com",
  projectId: "palms-crowdmark",
  storageBucket: "palms-crowdmark.appspot.com",
  messagingSenderId: "31218720519",
  appId: "1:31218720519:web:67d8dcb4a1a3fad6396fa2",
  measurementId: "G-4KJR5DSDKB",
};
