import * as firebase from "firebase";

import { FirebaseConfig } from "../config/keys";
firebase.initializeApp(FirebaseConfig);

const databaseRef = firebase.database().ref();
console.log("databaseRef", databaseRef);
export const todosRef = databaseRef.child("todos");

//PALMS
const ScoredatabaseRef = firebase.database().ref();
console.log("ScoredatabaseRef", ScoredatabaseRef);
export const scoreRef = ScoredatabaseRef.child("score");

const PDFdatabaseRef = firebase.database().ref();
console.log("PDFdatabaseRef", PDFdatabaseRef);
export const PDFListRef = PDFdatabaseRef.child("PDFList");
console.log("PDFListRef", PDFListRef);
