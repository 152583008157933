import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import App from "./App";
import { BrowserRouter, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "./assets/main.css";

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Provider store={store}>
        <App />
      </Provider>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.register();
