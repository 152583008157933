import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { PDFListRef } from "../config/firebase";
import * as actions from "../actions";
import * as firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

import ListItem from "./ListItem";
import "./style.css";

class EditPage extends Component {
  state = {
    showForm: false,
    username: "",
    filename: "",
    fileURL: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: "",
    fileNameValue: "example.pdf",
    dataid: "",
    selectedFile: null,
  };

  componentWillMount() {
    // this.props.fetchPDFList();
    // console.log(this.props.fetchPDFList());
  }

  render() {
    return (
      <div className="to-do-list-container">
        <button
          onClick={() => this.props.history.goBack()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          &lt; Back
        </button>
        <p className="text-2xl">
          EditPage Page <br /> Currently using React {React.version}
        </p>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    data,
  };
};

export default connect(mapStateToProps, actions)(EditPage);
