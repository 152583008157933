import React, { Component } from "react";
// import List from "./components/List";
import { Route, Link } from "react-router-dom";
import Home from "./components/Home";
import ViewFile from "./components/ViewFile.js";
import EditPage from "./components/EditPage";

class App extends Component {
  render() {
    return (
      <div className="container">
        <main>
          <Route exact path="/" component={Home} />
          <Route exact path="/view/:fileID" component={ViewFile} />
          <Route exact path="/edit/:fileID" component={EditPage} />
        </main>
      </div>
    );
  }
}
export default App;
