import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { PDFListRef } from "../config/firebase";
import * as actions from "../actions";
import * as firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { Document, Page, pdfjs } from "react-pdf";
// import throttle from "lodash.throttle";

import { AutoSizer } from "react-virtualized";

import ListItem from "./ListItem";
import "./style.css";

class ViewFile extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    viewData: "",
    fileID: "",
    fileURL: "",
    numPages: null,
    pageNumber: 1,
    PDFWidth: null,
  };

  myInput = React.createRef();

  componentDidMount() {
    const { fileID, oringinFileURL } = this.props.location.state;
    console.log("fileID, oringinFileURL", fileID, oringinFileURL);
    this.setState({ fileID: fileID, fileURL: oringinFileURL });

    //https://github.com/wojtekmaj/react-pdf/issues/321
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    this.setPDFWidth();

    // event listener when window is resized

    window.addEventListener("resize", this.throttledSetPDFWidth);
  }

  componentWillMount() {
    // this.props.fetchPDFList();
    // console.log(this.props.fetchPDFList());

    window.removeEventListener("resize", this.throttledSetPDFWidth);
  }

  handleBack() {
    this.props.history.push("/");
  }

  setPDFWidth = () => {
    const width = this.myInput.current.offsetWidth;
    console.log("myInput.current.offsetWidth ", width);
    this.setState({ PDFWidth: width });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  changePage = (offset) =>
    this.setState((prevState) => ({
      pageNumber: prevState.pageNumber + offset,
    }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  getDisplayData = (viewData) => {
    console.log("Data pass to the View Page", viewData);
  };

  render() {
    const { fileID, fileURL, pageNumber, numPages } = this.state;
    const { PDFWidth } = this.state;
    // const { data, viewData } = this.props;
    // console.log("fileID, oringinFileURL render", fileID, oringinFileURL);
    // console.log("data at viewpage", data);
    console.log(this.myInput);
    return (
      <div>
        <button
          onClick={() => this.props.history.goBack()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          &lt; Back
        </button>
        <p className="text-2xl">
          ViewFile Page <br /> Currently using React {React.version}
        </p>
        <p className="text-2xl">
          fileID: {this.state.fileID} <br /> fileURL: {this.state.fileURL}
        </p>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <div>
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={this.previousPage}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={this.nextPage}
          >
            Next
          </button>
        </div>

        <div ref={this.myInput}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Document
                file={this.state.fileURL}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  //width={PDFWidth}
                  width={width}
                />
              </Document>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    data,
  };
};

export default connect(mapStateToProps, actions)(ViewFile);
