import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { PDFListRef } from "../config/firebase";
import * as actions from "../actions";
import * as firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { Route, Link } from "react-router-dom";

// import FileUploader from "react-firebase-file-uploader";
import ListItem from "./ListItem";
import "./style.css";

class Home extends Component {
  state = {
    showForm: false,
    username: "",
    filename: "",
    fileURL: "",
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: "",
    fileNameValue: "example.pdf",
    dataid: "",
    selectedFile: null,
  };

  handleSetFilename = (event) =>
    this.setState({ filename: event.target.value });

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = (progress) => this.setState({ progress });

  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = (filename) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ avatarURL: url }));
  };

  saveDownloadURL = (url) => {
    console.log("saveDownloadURL url", url);
    this.setState({ fileURL: url });
  };

  uploadToFirebase = (
    file,
    originFileName,
    editVersionFileName,
    fileNameValue,
    uuid
  ) => {
    const { addPDF, editPDF } = this.props;
    const { fileURL } = this.state;

    //create unique folder link for the file upload
    var source_images_url = "source_images".concat("/", uuid);
    var sourceStorageRef = firebase.storage().ref(source_images_url);

    //create unique folder link for the file upload
    var edit_images_url = "edit_images".concat("/", uuid);
    var editStorageRef = firebase.storage().ref(edit_images_url);
    var object_key = "";

    let downloadPath = "";
    console.log("fileURL const", fileURL);

    var uploadSourceTask = sourceStorageRef.child(originFileName).put(file, {
      contentType: file.type,
    });

    var uploadEditTask = editStorageRef.child(editVersionFileName).put(file, {
      contentType: file.type,
    });

    uploadSourceTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads
      },
      function () {
        console.log("upload version for source");
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadSourceTask.snapshot.ref
          .getDownloadURL()
          .then(function (downloadURL) {
            console.log("Source available at", downloadURL);
            addPDF({
              fileID: uuid,
              filename: fileNameValue,
              oringinStoreID: originFileName,
              oringinFileURL: downloadURL,
              editVersionStoreID: editVersionFileName,
              editVersionFileURL: "",
            });
          });
        uploadEditTask.on(
          "state_changed",
          function (snapshot) {
            console.log("upload version for editing");
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          function (error) {
            // Handle unsuccessful uploads
          },
          function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            uploadEditTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                console.log("Edit available at", downloadURL);

                PDFListRef.orderByChild("fileID")
                  .equalTo(uuid)
                  .once("child_added", (snap) => {
                    console.log("snap", snap.key);
                    object_key = snap.key;
                    console.log("object_key", object_key);
                  })
                  .then(function (snapshot) {
                    var object_key = snapshot.key;
                    console.log("object_key in", object_key);
                    if (object_key) {
                      // value is an object containing one or more of the users that matched your email query
                      // choose a user and do something with it
                      editPDF(object_key, { editVersionFileURL: downloadURL });
                    } else {
                      // res.status(401).json({
                      //   error: "No user found"
                      // });
                      console.log("error");
                    }
                  });
              });
          }
        );
      }
    );
  };

  /* end of upload function */

  inputNameChange = (event) => {
    this.setState({ fileNameValue: event.target.value });
  };

  fileNameclear = () => {
    this.setState({ fileNameValue: "" });
  };

  inputFileChange = (event) => {
    console.log(event.target.files[0]);
    this.setState({ selectedFile: event.target.files[0] });

    let updateFileName = event.target.files[0].name;

    //remove file extension name
    updateFileName = updateFileName.replace(/\.[^/.]+$/, "");

    this.setState({ fileNameValue: updateFileName });
  };

  formSubmit = (event) => {
    let uuid = uuidv4();
    let file = "";
    let l_fileURL = "";
    let originFileName = "";
    let editVersionFileName = "";
    console.log("event", event);
    const { fileNameValue, dataid, selectedFile } = this.state;

    // const { dataid } = "df";
    const { addPDF } = this.props;
    file = this.state.selectedFile;

    console.log("file: ", file);
    event.preventDefault();

    if (file != null) {
      originFileName = fileNameValue.concat(
        "_",
        uuid.concat(file.name.substr(file.name.lastIndexOf(".") + 0))
      );

      console.log("originFileName", originFileName);

      editVersionFileName = uuid.concat(
        file.name.substr(file.name.lastIndexOf(".") + 0)
      );

      console.log("editVersionFileName", editVersionFileName);

      this.uploadToFirebase(
        file,
        originFileName,
        editVersionFileName,
        fileNameValue,
        uuid
      );

      console.log("l_fileURL", l_fileURL);
      console.log("fileURL state", this.state.fileURL);

      //insert data into the firebase
    } else {
      //insert data into the firebase
      addPDF({ filename: fileNameValue, id: uuid });
    }

    //reset empty value
    this.setState({ fileNameValue: "" });
    this.setState({ dataid: "" });
    this.setState({ selectedFile: null });

    //reset form render to false
    this.setState({ showForm: false });
  };

  renderForm = () => {
    const { showForm, fileNameValue, dataid } = this.state;
    if (showForm)
      return (
        <div className="bg-yellow-300 p-8">
          <form onSubmit={this.formSubmit}>
            <div>
              <div className="flex flex-col pb-4">
                <label className="bg-red-200 text-xl">File:</label>
                <input
                  type="file"
                  accept="image/png, .pdf"
                  onChange={this.inputFileChange}
                  // id=""
                />
              </div>
              <div className="flex flex-col pb-4">
                <label className="bg-red-200 text-xl">File name</label>
                <div className="flex flex-row items-center">
                  <input
                    className=""
                    value={fileNameValue}
                    onChange={this.inputNameChange}
                    id=""
                    type="text"
                  />
                  <div
                    className="ml-4 cursor-pointer rounded-full bg-black h-6 w-6 flex items-center justify-center text-white text-sm"
                    onClick={() => this.fileNameclear()}
                  >
                    X
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end item-center">
              <button
                className="mr-6 bg-red-400 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                onClick={() => this.setState({ showForm: !showForm })}
              >
                Upload Later
              </button>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Submit Now
              </button>
            </div>
            {/* <label>Avatar:</label>
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            {this.state.avatarURL} */}
            {/* {this.state.avatarURL && <img src={this.state.avatarURL} />} */}
            {/* <FileUploader
            accept="image/*"
            name="avatar"
            randomizeFilename
            sourceStorageRef={firebase.storage().ref("images")}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
          /> */}
          </form>
        </div>
      );
  };

  renderPDFList() {
    const { data } = this.props;
    console.log("data", data);

    const pdflist = _.map(data, (value, index) => {
      return <ListItem key={index} pdfId={index} pdf={value} />;
    });

    if (!_.isEmpty(pdflist)) {
      console.log("pdf list", pdflist);
      console.log(
        "\n\n\n\n>>>> React Version using",
        React.version,
        "\n\n\n\n"
      );
      return (
        <table className="table-fixed">
          <thead>
            <tr className="">
              <th className="border w-1/4 px-4 py-2">ID</th>
              <th className="border w-1/4 px-4 py-2">File Name</th>
              <th className="border w-1/4 px-4 py-2">View</th>
              <th className="border w-1/4 px-4 py-2">Edit</th>
              <th className="border w-1/4 px-4 py-2">Delete</th>
            </tr>
          </thead>
          <tbody>{pdflist}</tbody>
        </table>
      );
    } else {
      return (
        <div className="flex justify-center bg-red-200 p-8">
          <p className="text-2xl">No PDF files is in firebase Store!!</p>
        </div>
      );
    }
  }

  componentWillMount() {
    this.props.fetchPDFList();
    console.log(this.props.fetchPDFList());
  }

  render() {
    const { showForm } = this.state;
    return (
      <div className="to-do-list-container">
        <div className="row">
          {this.renderForm()}
          {this.renderPDFList()}
          <div className="fixed-action-btn">
            <button
              onClick={() => this.setState({ showForm: !showForm })}
              className="btn-floating btn-large black darken-4"
            >
              {showForm ? (
                <i className="large material-icons">-</i>
              ) : (
                <i className="large material-icons">+</i>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    data,
  };
};

export default connect(mapStateToProps, actions)(Home);
